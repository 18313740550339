$baseFontSize: 10px;
$desktopRapMaxWidth: 1160px;
$primary: #4563e9;
$secondary: darken(#46e6f7, 20);
$error: #f44336;
$primary_darken: darken($primary, 30);
$mobileRapSpace: 16rem;
$mobileRapHalfSpace: 8rem;
$black: #1c1e35;
$blue: #4563e9;
$inputWhite: #f8f9fd;
$inputHeight: 4rem;


@function rem($px) {
  @return #{$px / $baseFontSize}rem;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line > 1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 960px) {
    @content;
  }
}
.mainPage {
  .diveEduEventContainer {
    margin-top: 2rem;
  }

  .flexItem2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .event {
    display: flex;
    flex-direction: column;
    width: 49%;
  
    @include mobile {
      width: 100%;
      margin-bottom: 2rem;
  
      .title {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }
  
    .eventSlide {
      width: 100%;
      height: 47%;
      background: #000;
      margin-bottom: 1rem;
      position: relative;
  
      @include mobile {
        height: 35vh;
      }
  
      .bgSlide {
        width: 100%;
        height: 100%;
        overflow: hidden;
  
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
  
      .content {
        z-index: 2;
        position: absolute;
        left: 1rem;
        top: 1rem;
        background: rgba(white, .15);
        width: 280px;
        height: 280px;
        padding: .85rem 1rem;
        color: white;
  
        @include mobile {
          width: 50vw;
          height: 50vw;
        }
  
        .changeTextArea {
          width: calc(100% - 2rem);
          position: absolute;
          opacity: 0;
          pointer-events: none;
          transition: .2s;

          &.active {
            opacity: 1;
            pointer-events: inherit;
          }
        }
  
        h6 {
          font-family: 'Objektiv Mk2 W03 Black';
          font-size: 1.25rem;
          line-height: 1;
          margin-bottom: .75rem;
        }
  
        h2 {
          font-family: 'Objektiv Mk2 W03 Black';
          font-size: 2.35rem;
          line-height: 1;
          margin-bottom: .75rem;
        }
  
        p {
          font-family: 'Objektiv Mk2 W03 Bold';
          font-size: .7rem;
        }
  
        & > button {
          position: absolute;
          top: calc(100% - 70px);
          background: black;
          color: white;
          border: none;
          font-size: .85rem;
          font-family: 'KoreanSWGI_1';
          padding: .4rem 2.5rem .4rem .75rem;
          display: block;
  
          @include mobile {
            top: initial;
            bottom: 2rem;
          }
        }
  
        .controller {
          position: absolute;
          right: -70px;
          bottom: 0;
          display: flex;
  
          @include mobile {
            display: none;
          }
  
          button {
            width: 70px;
            height: 70px;
            background: black;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
  
            img {
              display: block;
            }
          }
        }
      }
    }
  
    .recruitment {
      flex: 1;
      display: flex;
      justify-content: space-between;
      height: 0%;
  
      @include mobile {
        display: none;
      }
  
      .item {
        background: #eeece7;
        width: calc(50% - 0.5rem);
        height: 100%;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        position: relative;
        cursor: pointer;
  
        h2 {
          font-size: 1.25rem;
          margin-bottom: 1rem;
        }
  
        p {
          color: #656259;
          font-size: .9rem;
        }
  
        img {
          position: absolute;
          width: auto;
          height: 55%;
          bottom: 2rem;
          left: 50%;
          transform: translate(-50%);
        }
      }
    }
  }
}