$baseFontSize: 10px;
$desktopRapMaxWidth: 1160px;
$primary: #4563e9;
$secondary: darken(#46e6f7, 20);
$error: #f44336;
$primary_darken: darken($primary, 30);
$mobileRapSpace: 16rem;
$mobileRapHalfSpace: 8rem;
$black: #1c1e35;
$blue: #4563e9;
$inputWhite: #f8f9fd;
$inputHeight: 4rem;


@function rem($px) {
  @return #{$px / $baseFontSize}rem;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line > 1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 960px) {
    @content;
  }
}
.pointPlace {
  display: flex;
  margin-top: 1rem;
  border-bottom: 1px solid rgba(black, .07);
  padding-bottom: 1rem;
}

.UI-buttonList {
  display: flex;
  flex-wrap: wrap;
  font-size: .9rem;

  &.left {
    .item {
      margin-left: 0 !important;
      margin-right: 0.6rem;
    }
  }

  .item {
    border-radius: 1000rem;
    margin-left: .6rem;
    padding: .4rem 1rem .3rem 1rem;
    background: #f2f3f7;
    cursor: pointer;
    transition: .2s;
    font-size: .8rem;
    user-select: none;

    &:hover {
      opacity: .5;
    }

    &.active,
    &:hover {
      background: $blue;
      color: white;
    }

    &.active {
      opacity: 1 !important;
    }
  }

  @include mobile {
    .item {
      margin-bottom: .6rem;
    }
  }
}