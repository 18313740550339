$baseFontSize: 10px;
$desktopRapMaxWidth: 1160px;
$primary: #4563e9;
$secondary: darken(#46e6f7, 20);
$error: #f44336;
$primary_darken: darken($primary, 30);
$mobileRapSpace: 16rem;
$mobileRapHalfSpace: 8rem;
$black: #1c1e35;
$blue: #4563e9;
$inputWhite: #f8f9fd;
$inputHeight: 4rem;


@function rem($px) {
  @return #{$px / $baseFontSize}rem;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line > 1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 960px) {
    @content;
  }
}
.instrListPage {

  .instrList {
    display: flex;
    flex-wrap: wrap;

    > .item {
      border: 1px solid #eee;
      width: 32.45%;
      margin-right: 1rem;
      cursor: pointer;
      transition: .2s;

      &:nth-child(3n) {
        margin-right: 0;
      }

      &:hover {
        background: #f2f3f7;

        .hash {
          border-top: 1px solid rgba(black, .1);
        }
      }
    }

    @include mobile {
      > .item {
        width: 100%;
        margin-right: 0;
        margin-bottom: 1.5rem
      }
    }

    .profile {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 1.2rem;
      padding: 2rem 2rem 0rem 2rem;


      .imgBox {
        margin-right: 1rem;
        img {
          width: 4rem;
          height: 4rem;
          border-radius: 50%;
        }
      }

      .nameBox {
        h3 {
          font-size: 1rem;
        }

        .nameEn {
          font-size: 1rem;
          font-weight: normal;
        }
      }
    }

    .content {
      padding: 0 2rem 0rem 2rem;

      .info {
        display: flex;
        justify-content: space-between;
        padding: .8rem 0;
        border-bottom: 1px solid rgba(black, .1);

        &:last-child {
          border-bottom: none;
          padding-bottom: 1.5rem;
        }

        h4 {
          font-weight: normal;
          font-size: .9rem;
        }

        p {
          font-weight: bold;
          font-size: .9rem;
        }
      }
    }

    .hash {
      margin-top: .8rem;
      padding: 2rem;
      background: #f2f3f7;
      border-top: 1px solid #f2f3f7;
      transition: .2s;
      
      h4 {
        font-size: .8rem;
      }

      .hashList {
        display: flex;
        flex-wrap: wrap;
        margin-top: .7rem;

        .item {
          background: $blue;
          color: white;
          padding: .4rem .7rem;
          border-radius: 1000px;
          font-size: .7rem;
          margin: .1rem .2rem .1rem 0;
          cursor: pointer;
        }
      }
    }
  }
}