$baseFontSize: 10px;
$desktopRapMaxWidth: 1160px;
$primary: #4563e9;
$secondary: darken(#46e6f7, 20);
$error: #f44336;
$primary_darken: darken($primary, 30);
$mobileRapSpace: 16rem;
$mobileRapHalfSpace: 8rem;
$black: #1c1e35;
$blue: #4563e9;
$inputWhite: #f8f9fd;
$inputHeight: 4rem;


@function rem($px) {
  @return #{$px / $baseFontSize}rem;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line > 1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 960px) {
    @content;
  }
}
$leftContainerWidth: 60%;
$containerGap: 5%;
$infoItemLabelWidth: 30%;

.scubaDetailPage {
  @include mobile {
    .container {
      flex-wrap: wrap;
      padding-bottom: 5rem !important;

      .leftContainer {
        width: 100% !important;
        margin-right: 0 !important;
        margin-bottom: 3rem;
      }

      .rightContainer {
        width: 100% !important;
      }
    }
  }

  .container {
    display: flex;
    padding-top: 3rem;
    padding-bottom: 10rem;

    .leftContainer {
      width: $leftContainerWidth;
      margin-right: $containerGap;

      > .head {
        margin-bottom: 2.5rem;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        position: relative;
    
        > .left {
          .starContainer {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
    
            .MuiIcon-root {
              color: #ffbf00;
              font-size: 1rem;
    
              &:not(:first-child) {
                margin-left: -.1rem;
              }
            }
          }
    
          h2 {
            font-size: 1.6rem;
            margin-bottom: .5rem;
          }
    
          h3 {
            font-size: 1rem;
            font-weight: 400;
            color: #777;
            margin-bottom: 1rem;
          }
    
          span {
            display: flex;
            align-items: center;
            font-size: .85rem;
            color: #888;
    
            img {
              width: 13px;
              height: 13px;
              margin-right: .2rem;
              display: block;
            }
          }
        }

        > .right {
          position: absolute;
          right: 0;
          bottom: 0;
          .scoreContainer {
            text-align: right;
            display: flex;
            align-items: center;

            .text {
              margin-right: .5rem;

              .scoreLabel {
                color: $primary;
                font-size: .9rem;
                margin-bottom: .2rem;
              }

              .reviewCount {
                color: #888;
                font-size: .7rem;
                white-space: nowrap;
              }
            }
          }
        }
      }

      .sectionTitle {
        font-size: 1.3rem;
        margin-top: 3rem;
        margin-bottom: 1em;
      }

      .infoItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 0;
        border-bottom: 1px solid #e9ecef;

        @include mobile {
          h6 {
            min-width: 9rem;
            white-space: nowrap;
          }
        }

        h6 {
          display: block;
          font-weight: 400;
          font-size: 1rem;
          width: $infoItemLabelWidth;
          max-width: $infoItemLabelWidth;
        }

        .content {
          width: 100%;
          color: #666;
          font-size: .9rem;
        }
      }
    }

    .rightContainer {
      width: 100% - $leftContainerWidth - $containerGap;
      position: relative;

      .sideContent {
        top: 3rem;
        position: sticky;
      }

      .costContainer {
        width: 100%;
        border: 1px solid #dbdbdb;
        padding: 1.5rem;

        .cost {
          font-size: 1.4rem;
          font-weight: bold;
          text-align: right;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 1.4rem;

          p {
            font-size: 1rem;
            font-weight: normal;
          }

          > div {
            color: $blue;
          }

          span {
            font-size: 1rem;
            margin-left: .1rem;
            color: #000;
            font-weight: normal;
          }
        }

        .info {
          font-size: .8rem;
          color: #777;
          margin-bottom: .7rem;
          border-bottom: 1px solid rgba(black, .1);
          padding-bottom: .7rem;

          &:last-child {
            margin-bottom: 0;
          }
        }

        button {
          margin-top: 1rem;
          width: 100%;
          background: $primary;
          color: white;
          border-radius: .2rem;
          padding: 1rem 0;
          cursor: pointer;
          outline: none;
        }
      }
    }
  }

  .counterList {
    margin-bottom: 1rem;
    max-height: 43vh;
    overflow-y: auto;
    border-top: 1px solid rgba(black, .1);
    border-bottom: 1px solid rgba(black, .1);


    > div:nth-child(1) {
      border-top: none;
    }

    > div:last-child {
      border-bottom: none;
      margin-bottom: 0 !important;
    }

    > div {
      margin-bottom: 1rem;
    }
  }

  .infoList {
    max-height: 10vh;
    overflow-y: auto;
  }

  .wishBtn {
    background: #fff !important;
    color: $blue !important;
    border: 1px solid $blue !important;
    margin-top: .4rem !important;
  }
}

.instrDetail {
  .profileBox {
    display: flex;
    align-items: center;

    > div:nth-child(1) {
      margin-right: 1rem;

      img {
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
      }
    }
  }

  .hashList {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .item {
      background: $blue;
      color: white;
      padding: .4rem .7rem;
      border-radius: 1000px;
      font-size: .7rem;
      margin: .1rem .4rem .1rem 0;
      cursor: pointer;
    }
  }
}