$baseFontSize: 10px;
$desktopRapMaxWidth: 1160px;
$primary: #4563e9;
$secondary: darken(#46e6f7, 20);
$error: #f44336;
$primary_darken: darken($primary, 30);
$mobileRapSpace: 16rem;
$mobileRapHalfSpace: 8rem;
$black: #1c1e35;
$blue: #4563e9;
$inputWhite: #f8f9fd;
$inputHeight: 4rem;


@function rem($px) {
  @return #{$px / $baseFontSize}rem;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line > 1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 960px) {
    @content;
  }
}
footer {
  width: 100%;
  background: #0f101b;
  color: white;
  padding: 2rem 0;

  .links {
    display: flex;
    align-items: center;
    flex-direction: column;

    img {
      display: block;
    }

    .logo {
      padding: 2.5rem 0;
      margin: 0 auto;

      @include mobile {
        padding: 0;
        margin-bottom: 1.25rem;
      }
    }

    .menu {
      display: flex;

      @include mobile {
        display: none;
      }

      > div {
        cursor: pointer;
      }

      div:not(:last-child) {
        margin-right: 3rem;
      }
    }
  }

  .copyRight {
    margin-top: 2rem;
    border-top: 1px solid rgba(white, .1);
    font-size: .85rem;
    opacity: .7;
    text-align: center;
    padding: 2rem;

    @include mobile {
      border-top: none;
      margin-top: 0;
      padding: 0;
      font-size: .75rem;
      padding-bottom: 6.5rem;
    }
  }
}

.faqDialog {
  
  .faq {
    background:#fff;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 40rem;
  
    .title {
      margin-bottom: 2rem;
  
      p {
        margin-top: .5rem;
        font-size: .9rem;
      }
    }
  
    input, textarea {
      border: 1px solid rgba(black, .1);
      resize: none;
      border-radius: .2rem;
      padding: 1.2rem 1.2rem;
      font-size: 1rem;
      margin-bottom: .7rem;
      width: 100%;
    }
  
    button {
      background: $blue;
      color: white;
      padding: 1.2rem 0;
      border-radius: .2rem;
      margin-top: 1rem;
      cursor: pointer;
    }
  
    @include mobile {
      & {
        width: 98vw;
      }
  
      input, textarea {
        width: 70vw;
      }
    }
  }

  .close {
    background: $blue;
    position: absolute;
    color: white;
    width: 2rem;
    height: 2rem;
    @include flexCenter;
    border-radius: 50%;
    right: 1.7rem;
    top: 1.7rem;
    cursor: pointer;
  }

  .MuiDialog-paperWidthSm {
    max-width: 100vw;
    margin: 0 1rem;
  }

  @include mobile {
    .MuiDialog-paperWidthSm {
      width: 100%;
    }

    .faq {
      width: 100%;

      input, textarea {
        width: 100%;
      }
    }
  }
}