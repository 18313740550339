$baseFontSize: 10px;
$desktopRapMaxWidth: 1160px;
$primary: #4563e9;
$secondary: darken(#46e6f7, 20);
$error: #f44336;
$primary_darken: darken($primary, 30);
$mobileRapSpace: 16rem;
$mobileRapHalfSpace: 8rem;
$black: #1c1e35;
$blue: #4563e9;
$inputWhite: #f8f9fd;
$inputHeight: 4rem;


@function rem($px) {
  @return #{$px / $baseFontSize}rem;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line > 1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 960px) {
    @content;
  }
}
.diveShot {
  margin-top: 10rem;
  position: relative;

  @include mobile {
    margin-top: 4rem;
  }

  h1 {
    font-family: 'Objektiv Mk1 W03 XBold';
    color: $blue;
    font-size: 8rem;
    font-weight: bold;
    position: absolute;
    display: block;
    z-index: 1;
    left: -1rem;
    top: -7.5rem;

    @include mobile {
      font-size: 5.6rem;
      left: 1rem;
      top: -5rem;
    }
  }

  #diveShotGridLayout {
    width: 100%;

    img {
      transition: .2s;
      cursor: pointer;
    }

    img:hover {
      transform: scale(1.08);
      z-index: 110;
    }
  }
}

.diveShot-slider {
  user-select: none;
  background: none;

  .btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: $blue;
    color: white;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    @include flexCenter;
    cursor: pointer;
    z-index: 999;
    user-select: none;

    span {
      font-size: 2rem;
    }

    &.left {
      left: -2rem;
    }

    &.right {
      right: -2rem;
    }
  }

  @include mobile {
    .btn {
      width: 3rem;
      height: 3rem;
      background: #fff;
      color: $blue;
    }

    .btn.left {
      left: 1rem;
    }

    .btn.right {
      right: 1rem;
    }
  }

  .pos {
    position: absolute;
    color: white;
    bottom: .4rem;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(black, .7);
    padding: .5rem 1rem .37rem 1rem;
    border-radius: 100px;
    font-size: .8rem;
  }

  .list {
    position: relative;
    width: 30rem;
    height: auto;
    background: none;
    overflow: hidden;

    img {
      width: 100%;
      display: block;
      user-select: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
      transition: .2s;

      &.active {
        opacity: 1;
      }
    }
  }
}

.homeDiveShotDialog {
  .MuiDialog-paperWidthSm {
    background: none;
    box-shadow: none;
  }

  .MuiDialog-paper {
    overflow-y: visible;
  }
}