$baseFontSize: 10px;
$desktopRapMaxWidth: 1160px;
$primary: #4563e9;
$secondary: darken(#46e6f7, 20);
$error: #f44336;
$primary_darken: darken($primary, 30);
$mobileRapSpace: 16rem;
$mobileRapHalfSpace: 8rem;
$black: #1c1e35;
$blue: #4563e9;
$inputWhite: #f8f9fd;
$inputHeight: 4rem;


@function rem($px) {
  @return #{$px / $baseFontSize}rem;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line > 1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 960px) {
    @content;
  }
}
.ui_input {
  font-size: rem(16px);

  .MuiIcon-root {
    font-size: rem(24px);
  }

  .MuiButton-root {
    font-size: rem(14px);
    box-shadow: none;
  }

  .MuiFormLabel-root {
    font-size: rem(16px);
  }

  legend {
    font-size: rem(12px) !important;
  }
  
  &.shadow {
    .MuiInputBase-root {
      background: white;
      border-radius: rem(8px);
      box-shadow: 0 rem(4px) rem(8px) rgba(0, 0, 0, .3);

      &.MuiOutlinedInput-adornedStart {
        padding-left: rem(20px);

        .MuiOutlinedInput-input {
          padding-left: rem(12px);
        }
      }

      &.MuiOutlinedInput-adornedEnd {
        padding-right: rem(20px);

        .MuiOutlinedInput-input {
          padding-right: rem(12px);
        }
      }

      .MuiInputBase-input {
        padding: rem(24px);
      }

      .MuiOutlinedInput-notchedOutline {
        border: none;
      }
    }
  }

  &.filled {
    .MuiOutlinedInput-notchedOutline {
      border-color: lighten($primary, 20) !important;
    }
    
    .MuiInputBase-root {
      /* background: lighten($primary, 30); */
      background: #f5f2ff;
      
      &.MuiInputBase-colorSecondary {
        /* background: lighten($secondary, 30); */
      }
    }
  }

  .MuiInputBase-root {
    font-size: inherit;

    &.Mui-focused {
      color: inherit;
    }

    &.MuiInputBase-colorSecondary {
      &.Mui-focused {
        color: $secondary;
      }
    }
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ui_input {
    .MuiInputAdornment-positionEnd {
      margin-left: 0;
    }
    button {
      /* min-width: 80px; */
      
      .MuiButton-label {
        display: flex;
        align-items: center;
  
        span {
          /* margin-right: 13px; */
        }

        p {
          padding-right: 10px;
          margin-left: 20px;
        }
      }
    }
  } 
}