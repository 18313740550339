$baseFontSize: 10px;
$desktopRapMaxWidth: 1160px;
$primary: #4563e9;
$secondary: darken(#46e6f7, 20);
$error: #f44336;
$primary_darken: darken($primary, 30);
$mobileRapSpace: 16rem;
$mobileRapHalfSpace: 8rem;
$black: #1c1e35;
$blue: #4563e9;
$inputWhite: #f8f9fd;
$inputHeight: 4rem;


@function rem($px) {
  @return #{$px / $baseFontSize}rem;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line > 1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 960px) {
    @content;
  }
}
.mobile .homeVisual {
  margin-bottom: 1rem;
}

.mobile {
  .visual-rap {
    padding: 0 !important;
  }

  .visual__right {
  }

  .homeVisual {
    flex-wrap: wrap;

    > div {
      width: 100%;
    }

    .homeHeader {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9999;
      background: rgba(255,255,255,.6);
      padding: 1.5rem 1.5rem;

      .right {
        h3 {
          display: none;
        }
        
        .profile {
          width: 3rem;
          height: 3rem;
        }
      }
    }

    .visual__left {
      order: 2;
      padding: 0 1.5rem;
      background: #f2f3f7;
      margin-top: 1.5rem;

      .searchBox {
        margin-top: -2rem;
        border-radius: 100px;

        input { border-radius: 100px; }
      }

      .posInfo { display: none; }

      .quickMenu {
        padding: 2rem 0rem;

        .item {
          width: 20%;
          text-align: center;

          img {
            width: 70%;
          }

          h2 {
            margin-top: .7rem;
          }
        }
      }
    }

    .visual__right {
      order: 1;
      height: auto;
      margin-bottom: 5rem;

      .slider, .swiper-container, .swiper-wrapper {
        height: 70vw;
      }
      
      .swiper-slide {
        width: 100% !important;
      }

      .slideInfo {
        position: relative;
        height: auto;
        top: 0;
        left: 0;
        width: 100%;
        padding-top: 0;

        .title {
          background: white;
          display: inline-block;
          padding: 1.2rem 1.5rem 1rem 2rem;
          margin-left: -2rem;
          margin-top: -4rem !important;
          margin-bottom: -.5rem !important;
        }

        .info {
          display: none;
        }

        .slideHeader {
          position: absolute;
          width: 100%;
          left: 0;
          top: -1.5rem;
          padding-left: .8rem;
          
          .slideBar {
            display: block;
            width: 100%;
            height: 4px;
            background: rgba(255,255,255,.5);
            position: relative;
            top: -2rem;
            left: .45rem;

            .progress {
              width: 50%;
              height: 100%;
              background: white;
              transition: .2s;
            }
          }

          h3 {
            display: none;
          }

          .slideIndex {
            position: relative;
            right: 1.5rem;
            color: white;
            letter-spacing: 2px;
            font-size: .8rem;
            font-family: 'Avenir Next LT Pro Demi';

            span {
              color: white;
            }
          }
        }

        .contentSlider {
          width: 100%;
        }

        .contentSlider > div {
          width: 100%;
        }

        .list {
          display: flex;
          width: 100%;
          padding-top: 0 !important;
          margin-top: -.5rem;

          .item {
            border-bottom: none !important;

            &:nth-child(1)::after,
            &:nth-child(2)::after {
              content: '/';
              font-weight: 300;
              color: #666;
              padding: 0 .2rem;
            }

            h3 {
              display: none;
            }

            p {
              color: #666;
              font-weight: 300;
              font-size: .8rem;
            }
          }

          .cost {
            width: 40%;
            position: relative;
            right: -1.3rem;
            
            p {
              color: #000;
              font-size: 2rem !important;
              font-family: 'KoreanSWGI_2' !important;
              font-weight: 900;
              position: absolute;
              right: 0;
              bottom: .3rem;
            }
          }
        }
      }

      .slide-bottom {
        display: none;
      }
    }
  }
}