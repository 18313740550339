$baseFontSize: 10px;
$desktopRapMaxWidth: 1160px;
$primary: #4563e9;
$secondary: darken(#46e6f7, 20);
$error: #f44336;
$primary_darken: darken($primary, 30);
$mobileRapSpace: 16rem;
$mobileRapHalfSpace: 8rem;
$black: #1c1e35;
$blue: #4563e9;
$inputWhite: #f8f9fd;
$inputHeight: 4rem;


@function rem($px) {
  @return #{$px / $baseFontSize}rem;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line > 1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 960px) {
    @content;
  }
}
.diveEdu {
  width: 49%;

  @include mobile {
    width: 100%;
    margin-bottom: 2rem;
  }

  .items {
    position: relative;

    .line_bg {
      background: url('~assets/images/dive_edu_line_bg.png');
      background-repeat: repeat-y;
      width: 13px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
    }

    .position {
      font-family: 'Avenir Next LT Pro Demi';
      line-height: 1;
      position: absolute;
      top: 10px;
      right: 20px;
      background: $blue;
      color: white;
      font-size: .7rem;
      padding: .2rem;
      padding-top: .36rem;
      border-radius: 3px;
      display: flex;
      transform: translateY(-50%);

      img {
        display: block;
        position: absolute;
        // height: 100%;
        right: -6px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .item {
      width: 100%;
      border-top: 1px solid $blue;
      padding: 1rem;
      height: 150px;

      &:last-child {
        border-bottom: 1px solid $blue;
      }
      
      .sub {
        font-weight: bold;
        color: #687094;
        margin-bottom: .6rem;
        padding-left: .1rem;
        font-size: 1.35rem;
        cursor: pointer;

        em {
          border-right: 1px solid #687094;
          margin-right: .4rem;
          padding-right: .4rem;
        }
      }

      h2 {
        font-family: 'KoreanSWGI_2';
        font-size: 2.4rem;
        color: $blue;
        cursor: pointer;
      }
    }
  }
}