$baseFontSize: 10px;
$desktopRapMaxWidth: 1160px;
$primary: #4563e9;
$secondary: darken(#46e6f7, 20);
$error: #f44336;
$primary_darken: darken($primary, 30);
$mobileRapSpace: 16rem;
$mobileRapHalfSpace: 8rem;
$black: #1c1e35;
$blue: #4563e9;
$inputWhite: #f8f9fd;
$inputHeight: 4rem;


@function rem($px) {
  @return #{$px / $baseFontSize}rem;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line > 1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 960px) {
    @content;
  }
}
$result_radius: 8rem;
$result_top_height: 16rem;

.mobile-searchPage {
  position: relative;
  height: 100vh;
  overflow: hidden;

  .searchBar {
    position: absolute;
    height: 12rem;
    left: 50%;
    top: 8rem;
    z-index: 1;
    display: flex;
    transform: translateX(-50%);

    .back {
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 5rem;
      box-shadow: 0 5px 10px rgba(black, .1);
      margin-right: 0.7rem;
      
      img {
        height: 4rem;
      }
    }

    .right {
      flex: 1;
      display: flex;
      background: white;
      box-shadow: 0 5px 10px rgba(black, .1);

      .input {
        flex: 1;

        input {
          width: 100%;
          height: 100%;
          padding-left: 3rem;
          font-size: 3.4rem;
          outline: none;
          font-weight: 600;

          &::placeholder {
            color: #999999;
          }
        }
      }

      .searchIcon {
        padding: 0 4rem;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 5rem;
        }
      }
    }
  }

  .map {
    position: relative;
    z-index: 0;
  }
  
  .result {
    width: 100%;
    position: absolute;
    top: 100%;
    transform: translateY(-$result_top_height);
    z-index: 2;
    transition: .4s transform;

    &.show {
      transform: translateY(-100%);
    }

    .head {
      background: $primary;
      border-top-left-radius: $result_radius;
      border-top-right-radius: $result_radius;

      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: $result_top_height;

        span {
          font-size: 5rem;
          color: white;
        }

        .arrowIcon {
          margin-top: 1rem;

          img {
            width: 5rem;
            display: block;
          }
        }
      }
    }

    .mobile-tabs {
      height: 10rem;

      .tab {
        padding: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .body {
      height: 60vh;
      overflow-y: auto;
      min-height: 60vh;
      background: white;
    }
  }
}

.mobile-searchDialog {
  .MuiDialog-paperWidthSm {
    width: 80%;
  }

  .close-button {
    .MuiSvgIcon-root {
      font-size: 4rem;
    }
  }

  .body {
    width: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6rem 5rem;

    img {
      display: block;
      width: 35rem;
      height: 35rem;
      object-fit: cover;
      object-position: center;
      margin-top: -17rem;
    }

    h2 {
      margin: 3rem;
      font-size: 5rem;
      font-weight: 500;
    }

    .info {
      text-align: center;
      font-size: 3.5rem;
      color: #777;

      em {
        display: inline-flex;
        align-items: center;

        &::before {
          content: '';
          display: block;
          width: 1px;
          height: 3rem;
          background: #bbbbbb;
          margin: 0 2rem;
        }
      }
    }
  }
}

.pointSearchMobileFontSize {
  font-size: 1vw !important;

  .rap {
    width: 88%;
    padding: 0;
  }
}