$baseFontSize: 10px;
$desktopRapMaxWidth: 1160px;
$primary: #4563e9;
$secondary: darken(#46e6f7, 20);
$error: #f44336;
$primary_darken: darken($primary, 30);
$mobileRapSpace: 16rem;
$mobileRapHalfSpace: 8rem;
$black: #1c1e35;
$blue: #4563e9;
$inputWhite: #f8f9fd;
$inputHeight: 4rem;


@function rem($px) {
  @return #{$px / $baseFontSize}rem;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line > 1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 960px) {
    @content;
  }
}
.UI-cardItem {
  width: 18.5rem;
  border: 1px solid #eee;
  cursor: pointer;
  transition: .2s;

  &:hover {
    background: #f2f3f7;
  }

  .imgBox {
    width: 18.5rem;
    height: 18.5rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content {
    padding: 1.5rem 2rem;
  }

  .place {
    color: #777;
    font-family: 'KoreanSWGI_1';
    font-size: .9rem;
    font-weight: 500;
    margin-bottom: .3rem;
  }

  .title {
    font-family: 'KoreanSWGI_2';
    font-size: 1rem;
    line-height: 1.3rem;
    cursor: pointer;

    span {
      color: #4563e9;
    }
  }

  .point {
    font-family: 'Avenir Next LT Pro Italic';
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: .4rem;

    span {
      color: #a0a0a0;
      font-size: .8rem;
    }
  }

  .score {
    display: flex;
    margin-top: .4rem;

    img {
      margin-right: .1rem;
    }
  }
}


.mobile {
  .mainPage {
    .tourList {
      flex-direction: inherit;
    }
    .UI-cardItem {
      .imgBox {
        width: 100% !important;
        height: 44vw !important;
      }
    }
  }
}


.mobile {

  .UI-cardItem {
    border: none;
    width: 44vw !important;
    margin-bottom: 1.7rem;

    .imgBox {
      width: 44vw !important;
      height: 44vw !important;
    }

    .btm {
      flex-wrap: wrap;
      margin-top: .5rem !important;
    }

    .content {
      background: #f2f3f7;
      padding: 1rem 1rem;

      .place {
        font-size: .75rem;
      }

      .title {
        font-family: 'KoreanSWGI_1';
        font-size: .8rem;
      }

      .score {
        .item {
          width: 1rem;
          margin: .25rem .2rem 0rem 0;

          img {
            width: 100%;
            object-fit: cover;
          }
        }
      }

      .point {
        font-family: 'KoreanSWGI_2';

        span {
          font-family: 'Avenir Next LT Pro Italic';
          font-size: 1.2rem;
          margin-left: .2rem;
          position: relative;
          top: .1rem;
        }
      }
    }
  }
}