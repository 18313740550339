$baseFontSize: 10px;
$desktopRapMaxWidth: 1160px;
$primary: #4563e9;
$secondary: darken(#46e6f7, 20);
$error: #f44336;
$primary_darken: darken($primary, 30);
$mobileRapSpace: 16rem;
$mobileRapHalfSpace: 8rem;
$black: #1c1e35;
$blue: #4563e9;
$inputWhite: #f8f9fd;
$inputHeight: 4rem;


@function rem($px) {
  @return #{$px / $baseFontSize}rem;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line > 1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 960px) {
    @content;
  }
}
$sidebarPadding: 2rem;

#simple-menu {
  .MuiMenuItem-root {
    display: flex;
    align-items: center;

    img {
      width: rem(20px);
      height: rem(20px);
      margin-right: rem(8px);
      fill: red;
    }
  }
}

.map-sidebar {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 5;
  left: 0;
  top: 0;
  width: 350px;
  height: 100vh;
  background: #fff;
  font-family: 'NotoSansKR';

  box-shadow: 0 0 5px lightgray;

  & .header {
    background: $primary;
    padding: $sidebarPadding;

    & .nav {
      display: flex;
      justify-content: space-between;
    }

    & .nav img {
      width: 20rem;
      margin-bottom: 1rem;
    }

    & .more {
      background: none;
      border: none;
      color: #fff;
    }

    & .search {
      margin: 1rem 0;
  
      & .keyword-input .MuiInputBase-root .MuiInputBase-input {
        padding-top: 2rem;
        padding-bottom: 2rem;
      }
  
      & .info {
        margin-top: 1.5rem;
        color: #fff;
        font-size: 1.2rem;
      }
    }
  }

  & .tab {
    padding: 0 $sidebarPadding;
    background: $primary;
    color: #fff;

    & .MuiTab-root {
      flex: 1;
      min-width: auto;
    }

    & .MuiTab-textColorInherit {
      opacity: 1;
    }

    & .Mui-selected {
      background: #fff;
      color: $primary;
    }

    & .MuiTabs-indicator {
      background: #fff;
    }
  }

  & .body {
    display: flex;
    flex-direction: column;
    padding: $sidebarPadding;
    height: 100%;
    overflow: auto;

    & .result-text {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-bottom: $sidebarPadding;

      & .result {
        font-size: 1.6rem;
      }

      & .highlight {
        color: $primary;
      }

      & .sort {
        font-size: 1.2rem;
        color: #666;
      }
    }


  }
}