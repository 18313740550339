$baseFontSize: 10px;
$desktopRapMaxWidth: 1160px;
$primary: #4563e9;
$secondary: darken(#46e6f7, 20);
$error: #f44336;
$primary_darken: darken($primary, 30);
$mobileRapSpace: 16rem;
$mobileRapHalfSpace: 8rem;
$black: #1c1e35;
$blue: #4563e9;
$inputWhite: #f8f9fd;
$inputHeight: 4rem;


@function rem($px) {
  @return #{$px / $baseFontSize}rem;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line > 1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 960px) {
    @content;
  }
}
.signDialog {
  .signContainer {
    width: 400px;
    height: 700px;
    overflow-y: auto;
  }

  .MuiDialog-paperFullScreen {
    .signContainer {
      width: 100%;
      height: 100%;
    }
  }


  // --- STRAW 코드 ---
  $primary: #4563e9;

  .sign {
    padding: 2rem;
    font-family: 'Objektiv Mk2 W03 Bold';

    header {
      position: relative;

      h1 {
        font-family: 'Objektiv Mk2 W03 black';
        font-size: 2rem;
        line-height: 1;
        margin-bottom: 2rem;
      }

      .backButton {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;

        img {
          width: 2rem;
        }
      }
    }

    form {
      .field {
        & > span {
          display: block;
          font-size: 1.2rem;
        }

        .MuiFormControl-root {
          margin-top: .5rem;

          .MuiInput-underline:after {
            border-color: $primary;
          }
          
          .MuiInputBase-root {
            font-size: 1rem;
            font-family: 'Objektiv Mk2 W03 Bold', 'NotoSansKR';
            
            input {
              padding: 1rem .5rem;
            }
          }
    
          &.kr {
            .MuiInputBase-root {
              input::placeholder {
                font-family: 'NotoSansKR';
              }
            }
          }

          .MuiSelect-root {
            padding: 1rem .5rem;
          }
        }

        .buttonInputContainer {
          display: flex;
          align-items: center;

          .MuiFab-root {
            margin-top: .5rem;
            min-width: 2.5rem;
            min-height: 2.5rem;
            width: 2.5rem;
            height: 2.5rem;
            background: #000;
            box-shadow: none;
            margin-left: 1rem;
            
            .MuiFab-label {
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: 2vmin;
              }
            }
          }
        }
      }
    }

    .signIn {
      form {
        margin-top: 1rem;
        margin-bottom: 1rem;

        .field + .field {
          margin-top: 2.5rem;
        }
      }

      .providers {
        margin-top: 3rem;
        margin-bottom: 3rem;

        h2 {
          font-size: 1.2rem;
          color: #999999;
          margin-bottom: 1rem;
        }

        img {
          width: 2.6rem;
          cursor: pointer;
          
          & + img {
            margin-left: .5rem;
          }
        }
      }

      .links {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 2rem;

        & > div {
          cursor: pointer;
          font-size: 1.2rem;
          color: #666666;

          &.active {
            position: relative;
            color: black;

            span {
              position: relative;
              z-index: 1;
            }

            &:before {
              content: '';
              position: absolute;
              bottom: -.2rem;
              left: 0;
              width: 100%;
              height: .7rem;
              background: $primary;
              z-index: 0;
            }
          }
        }
      }
    }

    .submitButtonContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.dark {
        .MuiFab-root {
          background: #000;
        }
      }

      & > span {
        font-size: 2rem;
      }

      .MuiFab-root {
        width: 4.5rem;
        height: 4.5rem;
        box-shadow: none;
        background: $primary;

        img {
          width: 3.5vmin;
        }
      }
    }

    .signUp {
      .stepViewer {
        display: flex;
        margin-top: 1rem;

        & > div {
          width: 1rem;
          height: 1rem;
          background: #dddddd;
          transition: .3s background;
          border-radius: 50%;

          & + div {
            margin-left: 1rem;
          }

          &.active {
            background: #000;
          }
        }
      }

      .step0 {
        padding-top: 8rem;

        .MuiButton-root {
          margin-bottom: 2rem;
        }
      }

      .step1 {
        margin-top: 1rem;

        .item {
          padding: 1rem 0;
          border-bottom: 1px solid #b5b5b5;
          font-family: 'NotoSansKR';

          .flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          .checkbox {
            label {
              font-size: .9rem;

              mark {
                white-space: nowrap;
                margin-left: 1vmin;
                background: none;
                color: #999999;

                &.required {
                  color: $primary;
                }
              }
            }
          }

          button {
            display: flex;
            align-items: center;
          }

          p {
            font-size: .7rem;
            margin-left: 3rem;
            color: #666;
          }
        }

        .submitButtonContainer {
          margin-top: 3rem;
        }
      }

      .step2 {
        .field {
          margin-top: 2.5rem;
        }

        .submitButtonContainer {
          margin-top: 3rem;
        }
      }

      .step3 {
        .field {
          margin-top: 2.5rem;
        }

        .buttonInputContainer:last-child {
          margin-top: 2vmin;
        }

        .submitButtonContainer {
          margin-top: 3rem;
        }
      }
    }
  }
}

.signDialogMobileFontSize {
  font-size: 16px !important;
}


.detailModal {
  position: fixed;
  background-color: #fff;
  padding: 2rem;
  width: 1000px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 80vh;
  z-index: 999;
  
  .content {
    height: calc(80vh - 4rem);
    overflow: auto;
    color: rgba(0,0,0,.7);
    font-size: .8rem;
  }

  .close {
    position: absolute;
    right: 1rem;
    top: .7rem;
    font-size: 1.2rem;
    cursor: pointer;
  }
}