$baseFontSize: 10px;
$desktopRapMaxWidth: 1160px;
$primary: #4563e9;
$secondary: darken(#46e6f7, 20);
$error: #f44336;
$primary_darken: darken($primary, 30);
$mobileRapSpace: 16rem;
$mobileRapHalfSpace: 8rem;
$black: #1c1e35;
$blue: #4563e9;
$inputWhite: #f8f9fd;
$inputHeight: 4rem;


@function rem($px) {
  @return #{$px / $baseFontSize}rem;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line > 1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 960px) {
    @content;
  }
}
.homeHeader,
.homeHeader .left,
.homeHeader .right {
  display: flex;
  align-items: center;
}

.homeHeader {
  max-width: 1260px;
  width: 100%;
  padding-top: 2.5rem;
  justify-content: space-between;

  img {
    cursor: pointer;
  }

  .left {
    .leftMenu {
      margin-right: 1.5rem;
      position: relative;
      top: -.1rem;
    }
  }

  .right {
    h3 {
      font-family: 'KoreanSWGI_1';
      font-size: 1rem;
    }

    .mh_icon {
      display: none;
    }

    .profile {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      background: url('~assets/images/icon/tmp_profile.png') center no-repeat;
      margin-left: 1.5rem;
    }
  }
}

.mobile .homeHeader .mh_icon {
  display: inline-block !important;
  position: relative;
  top: .2rem;

  img {
    width: 2.2rem;
    height: 2.2rem;
    margin-left: 1.1rem;
  }
}

.homeHeader.sub {
  padding-bottom: 2.5rem;

  @include mobile {
    & {
      justify-content: center;
      padding: 1.5rem 0;
    }

    .quickMenu {
      display: none;
    }
  }
}

.homeHeader  {

  .quickMenu {
    display: flex;
    justify-content: flex-end;

    .item {
      display: flex;
      align-items: center;
      width: auto;
      padding-left: 1.2rem;
      margin-left: 1.2rem;
      cursor: pointer;
      position: relative;

      &.active {
      }
      
      &:not(:first-child)::after {
        content: "";
        width: 1px;
        height: 100%;
        position: absolute;
        // background: rgba(black, .1);
        left: 0;
      }

      img {
        width: 1.5rem;
        margin-right: .5rem;
      }

      h2 {
        margin-top: 0;
        font-size: 1rem;
      }
    }
  }
}