$baseFontSize: 10px;
$desktopRapMaxWidth: 1160px;
$primary: #4563e9;
$secondary: darken(#46e6f7, 20);
$error: #f44336;
$primary_darken: darken($primary, 30);
$mobileRapSpace: 16rem;
$mobileRapHalfSpace: 8rem;
$black: #1c1e35;
$blue: #4563e9;
$inputWhite: #f8f9fd;
$inputHeight: 4rem;


@function rem($px) {
  @return #{$px / $baseFontSize}rem;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line > 1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 960px) {
    @content;
  }
}
.mobile {
  .mainPage {
    .tourList {
      overflow-x: auto;
    
      > .UI-cardItem {
        width: 67vw;
        min-width: 67vw;
        margin-right: 1rem;
        background: #f2f3f7;
    
        .imgBox {
          width: 100%;
        }
      }
  
      .point {
        text-align: right;
      }
  
      .btm {
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
      }
    }
  }
  
  .tourList {
    flex-wrap: wrap;

    > .UI-cardItem {
      width: 100%;
      background: #f2f3f7;
      margin-bottom: 1.5rem;
  
      .imgBox {
        width: 100%;
        height: 24rem;
      }
    }

    .point {
      text-align: right;
    }

    .btm {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
    }
  }
}