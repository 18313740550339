$baseFontSize: 10px;
$desktopRapMaxWidth: 1160px;
$primary: #4563e9;
$secondary: darken(#46e6f7, 20);
$error: #f44336;
$primary_darken: darken($primary, 30);
$mobileRapSpace: 16rem;
$mobileRapHalfSpace: 8rem;
$black: #1c1e35;
$blue: #4563e9;
$inputWhite: #f8f9fd;
$inputHeight: 4rem;


@function rem($px) {
  @return #{$px / $baseFontSize}rem;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line > 1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 960px) {
    @content;
  }
}
.counter {
  border: 1px solid rgba(black, .1);

  .content {
    padding: 1.5rem 1.5rem;
  }

  .info {

    h2 {
      font-size: 1rem;
      padding-bottom: .4rem;
    }
  
    .desc {
      font-size: .8rem;
      color: rgba(black, .7);
    }
  }

  .btm {
    border-top: 1px solid rgba(black, .1);
    margin-top: 1.3rem;
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;

    .people {
      display: flex;
      align-items: flex-end;

      > span {
        position: relative;
        bottom: .1rem;
      }

      .cost {
        display: flex;
        font-size: 1.4rem;
        align-items: flex-end;
        margin-left: .4rem;
  
        .won {
          font-size: .9rem;
          position: relative;
          bottom: .1rem;
        }
      }
    }

    .count {
      display: flex;
      align-items: center;

      .cnt {
        padding: 0 1rem;
        font-size: 1.4rem;
        font-weight: bold;
      }

      .btn {
        user-select: none;
        cursor: pointer;
        width: 2rem;
        height: 2rem;
        background: $blue;
        color: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f2f3f7;
    padding: 1rem 2rem;
    
    .cost {
      font-size: .8rem;
      
      span {
        font-weight: bold;
        color: $blue;
        font-size: 1.3rem;
        margin-right: .2rem;
      }
    }
  }
}