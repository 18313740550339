$baseFontSize: 10px;
$desktopRapMaxWidth: 1160px;
$primary: #4563e9;
$secondary: darken(#46e6f7, 20);
$error: #f44336;
$primary_darken: darken($primary, 30);
$mobileRapSpace: 16rem;
$mobileRapHalfSpace: 8rem;
$black: #1c1e35;
$blue: #4563e9;
$inputWhite: #f8f9fd;
$inputHeight: 4rem;


@function rem($px) {
  @return #{$px / $baseFontSize}rem;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line > 1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 960px) {
    @content;
  }
}
.mobile-searchResultList {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  & > .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5rem 0;

    .description {
      font-size: 3.5rem;
    }

    .sort {
      font-size: 3rem;
      color: #666;
    }
  }

  .Clist {
    .line span + span:before {
      height: 3rem;
      margin: 0 1.5rem;
    }

    .space {
      height: 8rem;
    }
  }

  .ClistItem.horizontal-item {
    align-items: center;

    + .horizontal-item {
      margin-top: 8rem;
    }

    .badge {
      width: 5rem;
      height: 5rem;
      font-size: 2rem;
    }

    .image {
      width: 24rem;
      height: 24rem;
      min-width: 24rem;
      min-height: 24rem;
      margin-right: 4rem;

      img {
        // min-width: 24rem;
        // min-height: 24rem;
        object-fit: cover;
        object-position: center;
        display: block;
      }
    }

    .content {
      width: calc(100% - 24rem - 4rem);
    }

    .title {
      font-size: 4rem;
      font-weight: 500;
      margin-bottom: 2rem;
    }

    .info {
      font-size: 3rem;

      .line {
        font-size: 3.5rem;
      }
    }
  }
}