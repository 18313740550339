$baseFontSize: 10px;
$desktopRapMaxWidth: 1160px;
$primary: #4563e9;
$secondary: darken(#46e6f7, 20);
$error: #f44336;
$primary_darken: darken($primary, 30);
$mobileRapSpace: 16rem;
$mobileRapHalfSpace: 8rem;
$black: #1c1e35;
$blue: #4563e9;
$inputWhite: #f8f9fd;
$inputHeight: 4rem;


@function rem($px) {
  @return #{$px / $baseFontSize}rem;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line > 1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 960px) {
    @content;
  }
}
.reviewContainer {
  .reviewList {
    .item {
      padding: 1rem 0;
      border-bottom: 1px solid #e9ecef;

      .head {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        .scoreBox {
          width: 2rem;
          height: 2rem;
          font-size: 1rem;
          margin-right: .7rem;
        }

        .scoreLabel {
          font-size: 1.1rem;
        }
      }

      .content {
        padding-left: calc(2rem - 16px);
        margin-bottom: 1rem;
        font-size: .85rem;
        color: #333;
        padding-top: .18rem;

        .icon {
          width: 16px;
          height: 16px;
          min-width: 16px;
          min-height: 16px;
          position: relative;
          border-radius: 50%;
          margin-right: .7rem;
        }

        p {
        }

        .good {
          display: flex;
          margin-bottom: .5rem;

          .icon {
            background: #33b893;

            &::before {
              content: "";
              width: 2px;
              height: 8px;
              position: absolute;
              top: 4px;
              left: 7px;
              background-color: #fff;
            }

            &::after {
              content: "";
              width: 8px;
              height: 2px;
              position: absolute;
              top: 7px;
              left: 4px;
              background-color: #fff;
            }
          }
        }

        .bad {
          display: flex;

          .icon {
            background: #adb5bd;

            &::after {
              content: "";
              width: 8px;
              height: 2px;
              position: absolute;
              top: 7px;
              left: 4px;
              background-color: #fff;
            }
          }
        }
      }

      .foot {
        padding-left: 2.7rem;
        font-size: .7rem;
        color: #777;
      }
    }
  }
}