$baseFontSize: 10px;
$desktopRapMaxWidth: 1160px;
$primary: #4563e9;
$secondary: darken(#46e6f7, 20);
$error: #f44336;
$primary_darken: darken($primary, 30);
$mobileRapSpace: 16rem;
$mobileRapHalfSpace: 8rem;
$black: #1c1e35;
$blue: #4563e9;
$inputWhite: #f8f9fd;
$inputHeight: 4rem;


@function rem($px) {
  @return #{$px / $baseFontSize}rem;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line > 1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 960px) {
    @content;
  }
}
.contact {
  width: 100%;
  background: url('~assets/images/footer_bg.png');
  background-position: center;
  background-size: cover;
  margin-top: 3rem;
  color: white;
  padding: 9rem 0;

  @include mobile {
    display: none;
  }

  .rap {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .left {
      h2 {
        font-family: 'Objektiv Mk2 W03 Black';
        line-height: 1;
        font-size: 5rem;
        margin-bottom: 3rem;
      }

      p {
        font-size: .85rem;
        margin-bottom: .25rem;

        em {
          color: #6b7eff;
        }
      }

      h3 {
        font-family: 'Objektiv Mk2 W03 Bold';
        color: #6b7eff;
        font-size: 2.5rem;
        margin-top: 4rem;
      }
    }

    .right {
      width: 340px;

      input, textarea {
        display: block;
        width: 100%;
        background: none;
        color: white;
        border: 1px solid rgba(white, .2);
        border-radius: 3px;
        padding: 1rem 1.5rem;
        font-size: .85rem;
        margin-bottom: .85rem;
        outline: none;

        &::placeholder {
          color: white;
        }
      }

      textarea {
        resize: none;
      }

      button {
        outline: none;
        border: 1px solid white;
        border-bottom: 2px solid white;
        background: none;
        color: white;
        border-radius: 3px;
        padding: .85rem 1.75rem;
        font-size: .85rem;
      }
    }
  }
}