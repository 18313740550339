$baseFontSize: 10px;
$desktopRapMaxWidth: 1160px;
$primary: #4563e9;
$secondary: darken(#46e6f7, 20);
$error: #f44336;
$primary_darken: darken($primary, 30);
$mobileRapSpace: 16rem;
$mobileRapHalfSpace: 8rem;
$black: #1c1e35;
$blue: #4563e9;
$inputWhite: #f8f9fd;
$inputHeight: 4rem;


@function rem($px) {
  @return #{$px / $baseFontSize}rem;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line > 1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 960px) {
    @content;
  }
}
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@font-face {
  font-family: 'NotoSansKR';
  font-style: normal;
  font-weight: 400;
  src: local('NotoSansKR'), url('~assets/font/NotoSansKR/NotoSansKR-Regular.otf');
}
@font-face {
  font-family: 'NotoSansKR';
  font-style: normal;
  font-weight: 500;
  src: local('NotoSansKR'), url('~assets/font/NotoSansKR/NotoSansKR-Medium.otf');
}
@font-face {
  font-family: 'NotoSansKR';
  font-style: normal;
  font-weight: 700;
  src: local('NotoSansKR'), url('~assets/font/NotoSansKR/NotoSansKR-Bold.otf');
}

@font-face {
  font-family: 'Avenir Next LT Pro Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Regular'), url('~assets/font/AvenirNextLTPro/AvenirNextLTPro-Regular.woff') format('woff');
}
  
@font-face {
  font-family: 'Avenir Next LT Pro Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Condensed Italic'), url('~assets/font/AvenirNextLTPro/AvenirNextLTPro-CnIt.woff') format('woff');
}
  
@font-face {
  font-family: 'Avenir Next LT Pro Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Italic'), url('~assets/font/AvenirNextLTPro/AvenirNextLTPro-It.woff') format('woff');
}
  
@font-face {
  font-family: 'Avenir Next LT Pro Ultra Light';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Ultra Light'), url('~assets/font/AvenirNextLTPro/AvenirNextLTPro-UltLt.woff') format('woff');
}
  
@font-face {
  font-family: 'Avenir Next LT Pro Ultra Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Ultra Light Italic'), url('~assets/font/AvenirNextLTPro/AvenirNextLTPro-UltLtIt.woff') format('woff');
}
  
@font-face {
  font-family: 'Avenir Next LT Pro Ultra Light Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Ultra Light Condensed'), url('~assets/font/AvenirNextLTPro/AvenirNextLTPro-UltLtCn.woff') format('woff');
}
  
@font-face {
  font-family: 'Avenir Next LT Pro Ultra Light Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Ultra Light Condensed Italic'), url('~assets/font/AvenirNextLTPro/AvenirNextLTPro-UltLtCnIt.woff') format('woff');
}
  
@font-face {
  font-family: 'Avenir Next LT Pro Medium Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Medium Condensed'), url('~assets/font/AvenirNextLTPro/AvenirNextLTPro-MediumCn.woff') format('woff');
}
  
@font-face {
  font-family: 'Avenir Next LT Pro Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Medium Italic'), url('~assets/font/AvenirNextLTPro/AvenirNextLTPro-MediumIt.woff') format('woff');
}
  
@font-face {
  font-family: 'Avenir Next LT Pro Medium Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Medium Condensed Italic'), url('~assets/font/AvenirNextLTPro/AvenirNextLTPro-MediumCnIt.woff') format('woff');
}
  
@font-face {
  font-family: 'Avenir Next LT Pro Demi';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Demi'), url('~assets/font/AvenirNextLTPro/AvenirNextLTPro-Demi.woff') format('woff');
}
  
@font-face {
  font-family: 'Avenir Next LT Pro Demi Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Demi Condensed'), url('~assets/font/AvenirNextLTPro/AvenirNextLTPro-DemiCn.woff') format('woff');
}
  
@font-face {
  font-family: 'Avenir Next LT Pro Demi Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Demi Condensed Italic'), url('~assets/font/AvenirNextLTPro/AvenirNextLTPro-DemiCnIt.woff') format('woff');
}
  
@font-face {
  font-family: 'Avenir Next LT Pro Demi Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Demi Italic'), url('~assets/font/AvenirNextLTPro/AvenirNextLTPro-DemiIt.woff') format('woff');
}
  
@font-face {
  font-family: 'Avenir Next LT Pro Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Bold'), url('~assets/font/AvenirNextLTPro/AvenirNextLTPro-Bold.woff') format('woff');
}
  
@font-face {
  font-family: 'Avenir Next LT Pro Bold Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Bold Condensed Italic'), url('~assets/font/AvenirNextLTPro/AvenirNextLTPro-BoldCnIt.woff') format('woff');
}
  
@font-face {
  font-family: 'Avenir Next LT Pro Heavy';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Heavy'), url('~assets/font/AvenirNextLTPro/AvenirNextLTPro-Heavy.woff') format('woff');
}
  
@font-face {
  font-family: 'Avenir Next LT Pro Heavy Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Heavy Italic'), url('~assets/font/AvenirNextLTPro/AvenirNextLTPro-HeavyIt.woff') format('woff');
}
  
@font-face {
  font-family: 'Avenir Next LT Pro Heavy Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Heavy Condensed Italic'), url('~assets/font/AvenirNextLTPro/AvenirNextLTPro-HeavyCnIt.woff') format('woff');
}

@font-face {
  font-family: 'KoreanSWGI_1';
  src: url('~assets/font/KoreanSWGI/KoreanSWGI1.woff');
}

@font-face {
  font-family: 'KoreanSWGI_2';
  src: url('~assets/font/KoreanSWGI/KoreanSWGI2.woff');
}

@font-face {
  font-family: 'KoreanSWGI_3';
  src: url('~assets/font/KoreanSWGI/KoreanSWGI3.woff');
}

@font-face {
  font-family: 'KoreanSWGI_4';
  src: url('~assets/font/KoreanSWGI/KoreanSWGI4.woff');
}

@font-face {
  font-family: 'Objektiv Mk1 W03 Black';
  src: url('~assets/font/Objektiv/Objektiv Mk1 W03 Black.ttf');
}

@font-face {
  font-family: 'Objektiv Mk1 W03 Bold Italic';
  src: url('~assets/font/Objektiv/Objektiv Mk1 W03 Bold Italic.ttf');
}

@font-face {
  font-family: 'Objektiv Mk1 W03 Italic';
  src: url('~assets/font/Objektiv/Objektiv Mk1 W03 Italic.ttf');
}

@font-face {
  font-family: 'Objektiv Mk1 W03 Light Italic';
  src: url('~assets/font/Objektiv/Objektiv Mk1 W03 Light Italic.ttf');
}

@font-face {
  font-family: 'Objektiv Mk1 W03 Medium';
  src: url('~assets/font/Objektiv/Objektiv Mk1 W03 Medium.ttf');
}

@font-face {
  font-family: 'Objektiv Mk1 W03 Regular';
  src: url('~assets/font/Objektiv/Objektiv Mk1 W03 Regular.ttf');
}

@font-face {
  font-family: 'Objektiv Mk1 W03 Thin Italic';
  src: url('~assets/font/Objektiv/Objektiv Mk1 W03 Thin Italic.ttf');
}

@font-face {
  font-family: 'Objektiv Mk1 W03 Thin';
  src: url('~assets/font/Objektiv/Objektiv Mk1 W03 Thin.ttf');
}

@font-face {
  font-family: 'Objektiv Mk1 W03 XBold Italic';
  src: url('~assets/font/Objektiv/Objektiv Mk1 W03 XBold Italic.ttf');
}

@font-face {
  font-family: 'Objektiv Mk1 W03 XBold';
  src: url('~assets/font/Objektiv/Objektiv Mk1 W03 XBold.ttf');
}

@font-face {
  font-family: 'Objektiv Mk2 W03 Black';
  src: url('~assets/font/Objektiv/Objektiv Mk2 W03 Black.ttf');
}

@font-face {
  font-family: 'Objektiv Mk2 W03 Bold Italic';
  src: url('~assets/font/Objektiv/Objektiv Mk2 W03 Bold Italic.ttf');
}

@font-face {
  font-family: 'Objektiv Mk2 W03 Bold';
  src: url('~assets/font/Objektiv/Objektiv Mk2 W03 Bold.ttf');
}

@font-face {
  font-family: 'Objektiv Mk2 W03 Light';
  src: url('~assets/font/Objektiv/Objektiv Mk2 W03 Light.ttf');
}

@font-face {
  font-family: 'Objektiv Mk2 W03 Medium';
  src: url('~assets/font/Objektiv/Objektiv Mk2 W03 Medium.ttf');
}

@font-face {
  font-family: 'Objektiv Mk2 W03 Regular';
  src: url('~assets/font/Objektiv/Objektiv Mk2 W03 Regular.ttf');
}

@font-face {
  font-family: 'Objektiv Mk3 W03 Medium';
  src: url('~assets/font/Objektiv/Objektiv Mk3 W03 Medium.ttf');
}

html, body, input, button, textarea {
  font-size: 16px;
  font-family: 'KoreanSWGI_1';
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input,
button,
textarea {
  border: none;
  outline: none;
}

em {
  color: $blue;
  font-style: normal;
}

a {
  color: inherit;
  text-decoration: inherit;

  em {
    color: $secondary;
  }
}

.rap {
  max-width: 1260px;
  width: 100%;
  margin: 0 auto;
}

.flexItem2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}


// 페이드 애니메이션
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}


// 페이지 이동시 애니메이션
.page-enter-active, .page-leave-active {
  transition: opacity .3s;
}

.page-enter, .page-leave-to {
  opacity: 0;
}


.mobile {
  overflow-x: hidden;

  .rap {
    padding: 0 1.5rem;
  }
}


.number {
  font-family: 'Avenir Next LT Pro Demi';
}

.vs-select__option {
  font-size: 1.2rem;
}

.PointSearchPage {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}


.scoreBox {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $primary;
  color: white;
  border-top-left-radius: .4rem;
  border-top-right-radius: .4rem;
  border-bottom-right-radius: .4rem;
}

.notListData {
  width: 100%;
  text-align: center;
  padding: 10rem 0 5rem;
}


.homeWrap {
  .UI-loading {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mainPage {
    opacity: 1;
    transition: .2s;
    
    &.hide {
      opacity: 0;
    }
  }
}

.detailImgList {
  display: flex;
  flex-wrap: wrap;

  .UI-image {
    width: 20rem !important;
    height: 20rem !important;
  }
}

.fadeAnimation {
  opacity: 1;
  transition: 200ms opacity;

  &.hide {
    opacity: 0;
    height: 0;
    pointer-events: none;
  }
}

.pageLoadingContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
