$baseFontSize: 10px;
$desktopRapMaxWidth: 1160px;
$primary: #4563e9;
$secondary: darken(#46e6f7, 20);
$error: #f44336;
$primary_darken: darken($primary, 30);
$mobileRapSpace: 16rem;
$mobileRapHalfSpace: 8rem;
$black: #1c1e35;
$blue: #4563e9;
$inputWhite: #f8f9fd;
$inputHeight: 4rem;


@function rem($px) {
  @return #{$px / $baseFontSize}rem;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line > 1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 960px) {
    @content;
  }
}
.ClistItem {
  display: flex;

  &.horizontal-item {
    position: relative;
    flex-direction: row;
    align-items: end;
    cursor: pointer;

    width: 100%;

    & + .horizontal-item {
      margin-top: 2rem;
    }

    & .image {
      width: 80px;
      height: 80px;
      position: relative;
      margin-right: 1rem;
      border: 1px solid rgba(0, 0, 0, .1);
      font-size: 0;
    }

    & .image img {
      width: 100%;
      height: 100%;
    }

    & .badge {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      width: 30px;
      height: 30px;
      background: $primary;
      font-size: 1.4rem;
      color: #fff;
      font-weight: bold;
      padding: 0;
    }

    & .content {
      width: calc(100% - 90px);
    }

    & .title {
      @include textOverflow();
      font-size: 2rem;
      margin-bottom: .8rem;
    }

    & .info {
      font-size: 1.2rem;
      color: #666;
    }
    & .info p {
      display: inline-block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & .action {
      display: flex;
      flex-direction: column;;
      position: absolute;
      right: 0;
      top: 0;
    }

    & .action button {
      width: 30px;
      height: 30px;
      background: none;
      border: 1px solid #ededed;
      border-radius: 50%;
      font-size: 0;
      background: #fff;
      color: #a7a7a7;
      transition: color 300ms ease;
    }

    & .action button .material-icons {
      font-size: 1.4rem;
    }

    & .action button:hover {
      color: $primary;
    }

    &.active {
      padding: 3rem 2rem;
      background: #f9f9f9;
      flex-direction: column;

      & .image {
        width: 160px;
        height: 160px;
        margin-left: auto;
        margin-right: auto;
      }

      & .content {
        width: 100%;
      }

      & .title {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 2rem;
      }

      & .action {
        position: static;
        margin-left: 1rem;
      }
    }
  }
}