$baseFontSize: 10px;
$desktopRapMaxWidth: 1160px;
$primary: #4563e9;
$secondary: darken(#46e6f7, 20);
$error: #f44336;
$primary_darken: darken($primary, 30);
$mobileRapSpace: 16rem;
$mobileRapHalfSpace: 8rem;
$black: #1c1e35;
$blue: #4563e9;
$inputWhite: #f8f9fd;
$inputHeight: 4rem;


@function rem($px) {
  @return #{$px / $baseFontSize}rem;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line > 1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 960px) {
    @content;
  }
}
.homeVisual {
  display: flex;
  margin-bottom: 3.75rem;
  
  > div {
    width: 50%;
  }

  .visual__left {
    padding-right: 2.5rem;

    .searchBox {
      width: 100%;
      position: relative;
      margin-top: 4rem;
      border: 3px solid #000;

      input {
        width: 100%;
        height: 4rem;
        background: white;
        padding-left: 1.4rem;
        font-family: 'KoreanSWGI_2';
        font-size: 1.3rem;
        line-height: 4.2rem;
      }

      img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1.5rem;
        cursor: pointer;
      }
    }

    .posInfo {
      padding-left: 1.4rem;
      display: flex;
      margin-top: 1rem;
      font-family: 'KoreanSWGI_1';
      font-size: 1.3rem;

      .time, .weather, .wind {
        font-family: 'Avenir Next LT Pro Regular';
        font-size: 1.4rem;
        font-weight: 500 !important;

        h4 {
          font-weight: 500 !important;  
        }
      }

      > div {
        position: relative;
        padding-right: 1.1rem;
        margin-right: 1.1rem;
        display: flex;
        align-items: center;

        h4 {
          display: flex;
          align-items: flex-start;

          span {
            font-size: .75rem;
          }
        }

        img {
          margin-right: .5rem;
        }

        &:not(:last-child):after {
          content: '';
          display: block;
          width: 1px;
          height: 1.1rem;
          background: #6b6b6b;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .quickMenu {
      display: flex;
      justify-content: space-between;
      padding: 6rem 1.5rem 0 1.5rem;

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        > div {
          height: 2.5rem;
        }

        h2 {
          font-family: 'NotoSansKR';
          margin-top: 1.2rem;
          font-size: .9rem;
          color: #494949;
          font-weight: 600;
        }
      }
    }
  }

  .visual__right {
    height: 29rem;
    position: relative;
    
    .visual-slider {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .slideInfo {
      position: absolute;
      width: 48%;
      height: calc(100% - 2.5rem);
      left: 1.25rem;
      top: 1.25rem;
      z-index: 99;
      background: #fff;
      padding: 1.5rem 1.25rem 0 1.25rem;
    
      > .item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }

      .top {
        .slideHeader {
          display: flex;
          justify-content: space-between;
          align-items: center;

          h3 {
            font-size: .95rem;
          }

          p {
            font-family: 'Avenir Next LT Pro Regular';
            font-weight: 600;
            font-size: .75rem;

            span {
              color: #999;
            }
          }
        }

        .contentSlider {
          position: relative;

          > div {
            position: absolute;
            
            > .contents {
              opacity: 0;
              transition: .2s;

              &.active {
                opacity: 1;
              }
            }
          }
        }

        .contents {

          .title {
            font-family: 'KoreanSWGI_1';
            font-size: 1.5rem;
            margin-bottom: .4rem;
            margin-top: 1rem;
            font-weight: 600;
          }

          .info {
            font-family: 'KoreanSWGI_1';
            font-size: .8rem;
          }

          .list {
            padding-top: 1.5rem;

            .item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: .9rem;
              padding: .55rem 0;
              font-weight: 600;

              h3 {
                font-size: .9rem;
              }
              
              &:not(:last-child) {
                border-bottom: 1px solid #dbdbdb;
              }

              &.cost p {
                font-family: 'Avenir Next LT Pro Italic';
                font-size: 1.5rem;
              }
            }
          }
        }
      }
    }
  }

  .slide-bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    
    .moreBtn {
      background: #000;
      font-family: 'KoreanSWGI_1';
      color: white;
      padding: .6rem 2.5rem .6rem .8rem;
      display: inline-block;
      font-size: .83rem;
      cursor: pointer;
    }
    
    .slideBtn {
      display: flex;
      position: relative;
      right: calc(-1.25rem - 3.7rem);
      user-select: none;

      > div {
        width: 3.7rem;
        height: 3.7rem;
        background: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}

