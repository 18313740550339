$baseFontSize: 10px;
$desktopRapMaxWidth: 1160px;
$primary: #4563e9;
$secondary: darken(#46e6f7, 20);
$error: #f44336;
$primary_darken: darken($primary, 30);
$mobileRapSpace: 16rem;
$mobileRapHalfSpace: 8rem;
$black: #1c1e35;
$blue: #4563e9;
$inputWhite: #f8f9fd;
$inputHeight: 4rem;


@function rem($px) {
  @return #{$px / $baseFontSize}rem;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin textOverflow($line: 1) {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line > 1 {
    white-space: normal;
    line-height: 1.2;
    height: 1.2em * $line;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}

@mixin sameWithHeight($line: 1, $space: 0) {
  width: (88rem - $space * $line) / $line;
  height: (88rem - $space * $line) / $line;
}

@mixin mobile {
  @media (max-width: 960px) {
    @content;
  }
}
.mainPage {
  .midVisual {
    height: 25rem;
    width: 100%;
    margin-top: 3.75rem;
    margin-bottom: 7rem;
  
    .content {
      width: 100%;
      height: 100%;
      background: url('~assets/images/tour_bg.png');
      font-family: 'KoreanSWGI_1';
  
      .box {
        background: white;
        display: inline-block;
        width: 31.25rem;
        position: absolute;
        opacity: 0;
        pointer-events: none;
        user-select: none;

        &.active {
          opacity: 1;
          pointer-events: inherit;
        }
        
        .profile {
          height: 5.2rem;
          padding: 1rem 2.5rem 1rem 2.5rem;
          display: flex;
          align-items: center;
           background: repeating-linear-gradient(-45deg, #f2f2f2, #f2f2f2 10px, #fff 0, #fff 20px);
  
          h2 {
            font-size: 1.1rem;
            font-family: 'KoreanSWGI_2';
            font-weight: normal;
            position: relative;
            padding-right: .5rem;
            margin-right: .5rem;
  
            &:after {
              content: '';
              position: absolute;
              width: 1px;
              height: 1rem;
              background: #999;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
            }
          }
  
          p {
            font-size: .7rem;
          }
  
          .imgBox {
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
            margin-right: 1.8rem;
  
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
  
        .info {
          padding: 1rem 2.5rem 1rem 2.5rem;
  
          > div {
            padding: .65rem 0;
            display: flex;
            justify-content: space-between;
            font-size: .85rem;
            font-weight: normal;
  
            &:not(:last-child) {
              border-bottom: 1px solid #ccc;
            }
  
            p {
              font-weight: 600;
            }
  
            .tag {
              display: flex;
  
              div {
                background: #707070;
                color: white;
                border-radius: 100rem;
                font-size: .7rem;
                padding: .3rem .6rem .3rem .6rem;
                margin-left: .3rem;
                cursor: pointer;
              }
            }
          }
        }
  
        .midVisualBtns {
          padding: 2.5rem 2.5rem 0 2.5rem;
          position: relative;
  
          &:after {
            content: '';
            display: block;
            width: calc(100% - 7.4rem);
            height: 10rem;
            background: white;
            position: absolute;
            left: 0;
            bottom: -50%;
            z-index: 2;
          }
  
          .slide-bottom {
            position: relative;
            z-index: 99;
          }
  
          .slideBtn {
            right: -2.5rem;
            cursor: pointer;
  
            > div {
              background: #4563e9;
            }
          }
        }
      }
    }
  }

  .slide-bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    
    .moreBtn {
      background: #000;
      font-family: 'KoreanSWGI_1';
      color: white;
      padding: .6rem 2.5rem .6rem .8rem;
      display: inline-block;
      font-size: .83rem;
      cursor: pointer;
    }
    
    .slideBtn {
      display: flex;
      position: relative;
      right: calc(-1.25rem - 3.7rem);

      > div {
        width: 3.7rem;
        height: 3.7rem;
        background: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}